// Containers

$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px, xxl: 1260px );

// Grid

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px, );

// Colors

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #333D47;
$orange: #FF4611;
$orange-active: #802309;
$grey: #5C666F;

$primary: $blue;
$secondary: $orange;
$info: $gray-200;

// Body

$body-color: $primary;

// Typography

/*@import url("https://use.typekit.net/jtk4kxu.css");*/

@font-face {
    font-family: "Metropolis-Black";
    src: url("/fonts/Metropolis-Black.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-BlackItalic";
    src: url("/fonts/Metropolis-BlackItalic.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-Bold";
    src: url("/fonts/Metropolis-Bold.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-BoldItalic";
    src: url("/fonts/Metropolis-BoldItalic.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-ExtraBold";
    src: url("/fonts/Metropolis-ExtraBold.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-ExtraBoldItalic";
    src: url("/fonts/Metropolis-ExtraBoldItalic.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-ExtraLight";
    src: url("/fonts/Metropolis-ExtraLight.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-ExtraLightItalic";
    src: url("/fonts/Metropolis-ExtraLightItalic.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-Light";
    src: url("/fonts/Metropolis-Light.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-LightItalic";
    src: url("/fonts/Metropolis-LightItalic.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-Medium";
    src: url("/fonts/Metropolis-Medium.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-MediumItalic";
    src: url("/fonts/Metropolis-MediumItalic.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-Regular";
    src: url("/fonts/Metropolis-Regular.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-RegularItalic";
    src: url("/fonts/Metropolis-RegularItalic.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-SemiBold";
    src: url("/fonts/Metropolis-SemiBold.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-SemiBoldItalic";
    src: url("/fonts/Metropolis-SemiBoldItalic.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-Thin";
    src: url("/fonts/Metropolis-Thin.woff") format('woff');
}

@font-face {
    font-family: "Metropolis-ThinItalic";
    src: url("/fonts/Metropolis-ThinItalic.woff") format('woff');
}


$enable-responsive-font-sizes: true;

$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.125;
$font-family-sans-serif: "Metropolis-Regular", Arial;

$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$headings-font-weight: $font-weight-bold;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1.125 !default;
$h6-font-size: $font-size-base !default;

$display1-size: 4rem;
$display2-size: 3.75rem;
$display3-size: 3.5rem;
$display4-size: 3.25rem;

$display1-weight: $font-weight-bolder;
$display2-weight: $font-weight-bolder;
$display3-weight: $font-weight-bolder;
$display4-weight: $font-weight-bolder;

$strong-weight: $font-weight-bold;
$weight-bold: $font-weight-bold;

// Links

$link-color: $secondary;

// Buttons + Forms

$input-btn-padding-y: .75rem;
$input-btn-padding-x: 1rem;
$input-btn-font-size: 1rem;

// Buttons

$btn-font-weight: $font-weight-bolder;
$btn-border-width: 2px;

// Breadcrumbs

$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;

$breadcrumb-margin-bottom: 0.75rem;

$breadcrumb-bg: none;
$breadcrumb-active-color: $secondary;

// Border radius
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;


// Navbar

$navbar-padding-y: 0;

$navbar-nav-link-padding-x: 1rem;

$navbar-brand-padding-y: 1.25rem;

$navbar-toggler-padding-y: .25rem;
$navbar-toggler-padding-x: .25rem;
$navbar-toggler-border-radius: 3px;

$navbar-light-color: $gray-700;
$navbar-light-active-color: $black;
$navbar-light-toggler-icon-bg: none;

// Cards

$card-border-color: none;
$card-bg: none;

// List group

$list-group-bg: none;
$list-group-border-color: none;

$list-group-hover-bg: $gray-700;
$list-group-active-bg: $secondary;

$list-group-action-color: $white;
$list-group-action-hover-color: $white;
$list-group-action-active-color: $white;
$list-group-action-active-bg: $black;


// hr

$hr-border-color: $gray-500;
$hr-margin-y: 2rem;


// Modals

// Padding applied to the modal body
$modal-inner-padding: 1.5rem;

$modal-content-border-color: none;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;

$modal-backdrop-opacity: .25;


// Badges

$badge-font-size: 0.875rem;
$badge-padding-y: .5em;
$badge-padding-x: .5em;
