.navbar {
	width: 100%;
	.container {
		position: relative;
	}

	.navbar-brand {
		display: flex;
		align-items: flex-end;
		&:hover {
			img:last-child {
				transform: rotate(360deg);
			}
		}
		img {
			margin-top: auto;
			transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
			&:not(:last-child) {
				margin-right: 4px;
			}
		}
	}
	.navbar-toggler {
		width: 48px;
		height: 48px;
		overflow: hidden;
		border: none;
		position: relative;

		transition: background-color 0.25s, color 0.25s;
		// Default states of toggle
		&.collapsed {
			background-color: $secondary;
			color: $white;
			.navbar-toggler-icon {
				&.toggle-open {
					transform: translateY(0);
				}
				&.toggle-close {
					transform: translateY(-100%);
				}
			}
		}
		// When toggle is active
		&:not(.collapsed) {
			background-color: $primary;
			color: $white;
		}
		.navbar-toggler-icon {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			display: flex;
			height: 100%;
			justify-content: center;
			align-items: center;
			transition: transform 0.25s;
			.icon {
				line-height: 48px; // This is to ensure font awesome icons are centered
			}
			&.toggle-open {
				transform: translateY(100%);
			}
		}
	}
	.navbar-nav {
		.nav-link {
			font-weight: $font-weight-bold;
			font-size: 1.25rem;
			padding-bottom: 0.5rem;
			padding-top: 0.5rem;
		}
		.flyout {
			.flyout-content {
				.flyout-link {
					// Flyout headings
					&.h4 {
						display: flex;
						.icon {
							margin-right: 0.75rem;
						}
					}
					// Flyout links
					&:not(.h4) {
						color: $blue;
					}
				}
			}
		}
	}
	&.expanded {
		box-shadow: 0 5px 50px rgba(0, 0, 0, 0.35);
	}
}

// Mobile Down
@include media-breakpoint-down(md) {
	.navbar {
		.navbar-brand {
			padding-top: 0.875rem;
			padding-bottom: 0.875rem;
		}
	}
	.navbar-collapse {
		.navbar-nav {
			padding-top: 1rem;
			padding-bottom: 2rem;
			.nav-link {
				width: 50%;
			}
			.flyout {
				.flyout-content {
					padding-top: 1rem;
					margin-bottom: 1rem;
					.flyout-link {
						// Mobile Category Tiles
						&.h4 {
							height: 8.5rem;
							flex-flow: column;
							text-align: center;
							background: $secondary;
							color: $white;
							padding: 2rem 0.5rem;
							border-radius: 3px;
							justify-content: center;
							transition: background-color 0.25s;
							align-items: center;
							&:active,
							&:focus,
							&:hover,
							&:visited {
								text-decoration: none;
							}
							&:active {
								background-color: $orange-active;
							}

							.icon {
								font-size: 2em;
								margin-right: 0;
								margin-bottom: 0.75rem;
							}
						}
						&:not(.h4) {
							display: none;
						}
					}
				}
			}
		}
	}
}

// Large Desktop Down
@include media-breakpoint-down(lg) {
	.navbar-collapse {
		.navbar-nav {
			.flyout {
				.flyout-content {
					.flyout-link {
						&.h4 {
							font-size: 1rem;
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.navbar-collapse {
		.navbar-nav {
			margin-left: auto;
			.nav-link {
				padding-top: 2rem;
				padding-bottom: 2rem;
				border-bottom: 4px solid transparent;
				transition: color 0.25s, border-color 0.25s,
					background-color 0.25s;
				&.active {
					border-color: $orange;
				}
				&:not(.active):hover {
					color: $black;
					border-color: $primary;
					background-color: $gray-100;
				}
				&:not(.active):active {
					background-color: $gray-200;
				}
			}
		}

		.flyout {
			// When hovered - shows flyout content
			.flyout-content {
				visibility: hidden;
				opacity: 0;
				position: absolute;
				top: 100%;
				left: -100px; // Offsets flyout container width
				right: -100px; // Offsets flyout container width
				padding-left: 100px; // Negates offset
				padding-right: 100px; // Negates offset
				padding-top: 2rem;
				padding-bottom: 3rem;
				background: #fff;
				z-index: 2;
				transition: opacity 0.25s, transform 0.25s, box-shadow 0.25s;
				box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
				.row {
					// Nav sections
					.col-md-3 {
						&:first-child {
							margin-bottom: 2rem;
						}
					}
				}
				.flyout-link {
					display: block;
					font-weight: $font-weight-bold;
					// Flyout headings
					&.h4 {
						align-items: center;
						margin-bottom: 1.5rem;
						&:hover {
							text-decoration: none;
							span {
								text-decoration: underline;
							}
						}
					}
					// Flyout links
					&:not(.h4) {
						margin-bottom: 1rem;
						line-height: 1.3;
					}
				}
			}
		}
		.flyout:hover {
			.flyout-content {
				visibility: visible;
				opacity: 1;
				box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.35);
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.navbar-collapse {
		margin-right: 100px; // This offsets the logo's width
		.navbar-nav {
			margin-right: auto;
		}
	}
}

@include media-breakpoint-only(lg) {
	.navbar-collapse {
		.navbar-nav {
			.nav-link {
				padding-top: 1.5em;
				padding-bottom: 1.5rem;
			}
		}
	}
}

@media (max-height: 750px) and (min-width: 992px) {
	.navbar-collapse {
		.flyout {
			.flyout-content {
				.row {
					// Nav sections
					.col-md-3 {
						&:first-child {
							margin-bottom: 1rem;
						}
					}
				}
				.flyout-link {
					// Flyout headings
					&.h4 {
						margin-bottom: 1rem;
						font-size: 1rem;
					}
					// Flyout links
					&:not(.h4) {
						margin-bottom: 0.875rem;
						font-size: 0.875rem;
					}
				}
			}
		}
	}
}
