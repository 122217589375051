@include media-breakpoint-up(sm) {
.services-landing{
    section{
        &:not(.page-title){
            margin-bottom:5rem;
        }
    }
}
}

@include media-breakpoint-up(lg) {
    .services-landing{
        section{
            &:not(.page-title){
                margin-bottom:8rem;
            }
        }
    }
}


@include media-breakpoint-up(xl) {
    .services-landing{
        section{
            &:not(.page-title){
                margin-bottom:10rem;
            }
        }
    }
}