.row {
	&.center-shape {
		position: relative;
		&:before {
			content: "";
			background-image: url(../../src/images/shapes/circle-dots.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: 35%;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
		}
	}
}

@include media-breakpoint-down(xs) {
	.row {
		&.center-shape {
			.image {
				margin-top: 10px;
				margin-bottom: 10px;
			}
			&:before {
				content: "";
				background-size: 80%;
			}
		}
	}
}
