// Pagination

.pagination-controls {
	.pagination {
		justify-content: center;
	}
}

// Awards

.award {
	&.card {
		.image {
			img {
				opacity: 1;
			}
		}
	}
	.row {
		.award-description {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
}

// Awards page

.award-detail {
	.award-image {
		margin-bottom: 20px;
		@include media-breakpoint-up(sm) {
			margin-bottom: 0;
		}
	}
	.award-text {
		align-self: center;
	}
	.back-button {
		margin-top: 30px;
	}
}

.news-tab-content {
	width: 100%;
}

#loadMore {
	width: 100%;
}

// Newsletter list

.display_archive {
	.campaign {
		color: #6c757d;
		font-size: 1rem;
		padding: 24px 0;
		border-bottom: 1px solid #ccc;
		display: flex;
		flex-direction: column;

		a {
			font-size: 1.2rem;
		}
	}
}