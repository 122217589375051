aside{
    .primary-contact{
        padding-bottom:1.5rem;
        border-bottom:1px solid $gray-500;
        margin-bottom:1rem;
    }
    .other-contacts{
        h4{
            display:none;
        }
    }
}

@include media-breakpoint-down(md) {
    aside{
        margin-top:3rem;
        .btn{
            margin-bottom:0;
        }
    }
}