.error-404 {
	.error-404-message {
		text-align: center;
		h1 {
			margin-bottom: 1rem;
		}
		.icon {
			color: $secondary;
			margin-bottom: 1rem;
		}
	}
}

@include media-breakpoint-up(sm) {
	.error-404 {
		.error-404-message {
			margin-top: 6rem;
			margin-bottom: 6rem;
		}
	}
}
