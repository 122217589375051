.article-meta {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 1rem;
	h6 {
		margin-right: 1rem;
		margin-bottom: 0;
	}
	.badge {
		&:not(:last-child) {
			margin-right: 0.5rem;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}
}

// Article controls

.article-controls {
	display: flex;
	align-items: center;
	margin-bottom: 3rem;
	flex-flow: wrap;
	justify-content: space-between;
	.social-icons {
		margin: 1rem 0;
	}
	a {
		margin-bottom: 0;
	}
}

@include media-breakpoint-up(md) {
	// Article controls
	.article-controls {
		margin-bottom: 5rem;
	}
}
