.contact-us-background{
    background-position: center;
    background-repeat: no-repeat;
    background-color: $primary;
}
.contact-us-form{
    background:$white;
    color:$primary;
    padding-left:1rem;
    padding-right:1rem;
    padding-top:2rem;
    padding-bottom:2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    form{
        margin-bottom:0;
    }
}

.contact-us-tab-content{
    width:100%;
}

@include media-breakpoint-up(md) {
    .contact-us-form{
        margin-top:3rem;
        margin-bottom:3rem;
    }
}
@include media-breakpoint-up(lg) {
    .contact-us-background{
        background-size:50%;
    }
    .contact-us-form{
        margin-top:5rem;
        margin-bottom:5rem;
    }
}

.map{
    .image{
        iframe{
            position: absolute;
            top:0;
            left:0;
            bottom:0;
            right:0;
            width:100%;
            height:100%;
        }
    }
    .branch-address{
        margin-top:2rem;
    }
}

.branch{
    .branch-links{
        margin-top:2rem;
        a{
            display: block;
        }
    }
}

@include media-breakpoint-down(sm) {
    .branch{
        .image{
            display:none;
        }
        .branch-links{
            display:flex;
            flex-wrap:wrap;
            margin-top:0;
            margin-bottom:1rem;
            a{
                &:not(:last-child){
                    margin-right:2rem;
                }
            }
        }
    }
}
