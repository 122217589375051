.meet-the-team {
	margin-bottom: 3rem;
	.accordion.group {
		.department-heading {
			display: none;
			&:not(:first-child) {
				margin-top: 2rem;
				margin-bottom: 0.5rem;
			}
		}
	}
	.team-nav {
		background-color: $primary;
		margin-bottom: 0;
		h4 {
			color: $white;
		}
		.list-group {
			.list-group-item {
				border-radius: 2rem;
				transition: background-color 0.2s;
				&.active {
					z-index: 0;
				}
			}
		}
	}
	// .toggle {
	//     i {
	//         cursor: pointer;
	//         transition: transform 0.2s;
	//         &.active {
	//             transform: rotate(180deg);
	//         }
	//     }
	// }

	.tab-content {
		padding-top: 2rem;
		border: 1px solid $gray-500;
		.team-heading,
		.department-heading {
			padding-left: 1rem;
			padding-right: 1rem;
		}
		.accordion-heading {
			.image,
			h4,
			h6,
			.icon {
				pointer-events: none;
			}
		}
	}

	// .card{
	//     .tab-content {
	//         min-height: 100%;
	//     }
	// }
}

@include media-breakpoint-up(md) {
	.meet-the-team {
		margin-bottom: 5rem;
		.team-nav {
			display: block;
		}
		// .toggle{
		//     display:none;
		// }
	}
}

@include media-breakpoint-up(lg) {
	.meet-the-team {
		.tab-content {
			.team-heading,
			.department-heading {
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}
	}
}
