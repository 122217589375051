#animated-text{
    margin-bottom: 2rem;
    transition: all;
    #word{
        color:$secondary;
    }
}


@include media-breakpoint-down(md) {
    #animated-text{
      #word{
          display:block;
      }
    }
  }
  

@include media-breakpoint-up(lg) {
    #animated-text{
        margin-bottom:4rem;
    }
}

.swiper.hero{
  width:100%;
  background-color:$primary;
  position: relative;
  margin-bottom:2rem;
  overflow: hidden;
  .swiper-container {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      &.hero-top {
          width: 100%;
          .image-shape{
              // Shape position
              top: -45%;
              right: -65%;
              width: 100%;
              padding-top: 100%;
              z-index: 2;
              transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1);
              img{
                  bottom: 0;
                  left: 0;
                  position: absolute;
                  right: 0;
                  top: 0;
              }
          }
          .swiper-wrapper{
              height:100vh;
              position:relative;
              .swiper-slide {
                  
                  display: flex;
                  align-items: flex-end;
                  background-size: cover;
                  background-position: center;
                  &:hover{
                      cursor: move;
                      cursor:grab;
                  }
                  &:active{
                      cursor:grabbing;
                  }
                  &:before{
                      content: "";
                      background: linear-gradient(45deg, rgba(0,0,0,0.5) 0%, rgba(0, 0, 0, 0) 65%);
                      z-index: 0;
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                  }
                  .swiper-text{
                      margin-top:auto;
                      position: absolute;
                      bottom: 0;
                      left: 0;
                      z-index: 1;
                      .btn{
                        margin-bottom:0;
                      }
                      h1, h3{
                          color:$white;
                          text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
                      }
                      h1{
                          margin-bottom:1.5rem;
                      }
                      h3{
                          margin-bottom:0.5rem;
                      }
                  }
              }
          }
      }
      &.hero-thumbs {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          .swiper-slide {
              padding:1rem;
              height: auto;
              opacity: 0.8;
              display:flex;
              align-items:center;
              border-top:2px solid $white;
              transition: opacity 0.25s, background-color 0.25s;
              cursor: pointer;
              img{
                  display:none;
                  width:100%;
                  max-width:60px;
                  margin-right:1rem;
              }
              h6{
                  display:none;
                  color:$white;
                  margin-bottom:0;
              }
              &:hover{
                  opacity: 1;
                  background-color: rgba(0,0,0,0.15);
              }
          }
          .swiper-slide-thumb-active {
              opacity: 1;
              border-color:$secondary;                
          }
      }
  }
}
@include media-breakpoint-down(sm) {
  .swiper.hero{
      .swiper-container{
          &.hero-top {
              .swiper-wrapper{
                max-height: 600px;
              }
          }
      }
  }
}

@include media-breakpoint-up(sm) {
  .swiper.hero{
      .swiper-container {
          &.hero-top {
              .image-shape{
                  // Shape position
                  top: -55%;
                  right: -45%;
                  width: 70%;
                  padding-top: 70%;
              }
          }
      }
  }
}

@include media-breakpoint-up(md) {
  .swiper.hero{
      margin-bottom:3rem;
      .swiper-container{
          &.hero-top{
              .image-shape{
                  // Shape position
                  top: -20%;
                  right: -20%;
                  width: 60%;
                  padding-top: 60%;
              }
              .btn-secondary:hover{
                  background:$secondary;
                    color:$white;
                    box-shadow: 0 4px 10px rgba(0,0,0,0.5);
              }
          }
          &.hero-thumbs {
              .swiper-slide {
                  h6{
                      display:block;
                  }
              }
              .swiper-slide-thumb-active {          
                  background-color:rgba(0,0,0,0.4);
              }
          }
      }
  }
}


@include media-breakpoint-up(lg) {
  .swiper.hero{
      margin-bottom:5rem;
      .swiper-container{
          &.hero-top {
              .image-shape{
                  // Shape position
                  top: -20%;
                  right: -20%;
                  width: 60%;
                  padding-top: 60%;
              }
          }
          &.hero-thumbs { 
              .swiper-slide {
                img{
                    display:block;
                }
                padding:2rem;
              }
          }
      }
  }
}

@include media-breakpoint-up(xl) {
  .swiper.hero{
      .swiper-container{
          &.hero-top {
              .image-shape{
                  // Shape position
                  top: -40%;
                  right: -10%;
                  width: 50%;
                  padding-top: 50%;
              }
          }
      }
  }
}